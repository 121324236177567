import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Navbar from './Components/Navbar.jsx';
import Footer from './Components/Footer.jsx';

import Home from './Views/Home/Home.jsx';
import SobreMi from './Views/SobreMi/SobreMi';
import NotFound from './Views/NotFound/NotFound';
import Resume from './Views/Resume/Resume.jsx';
import Portafolio from './Views/Portafolio/Portafolio.jsx';
import ContactForm from './Components/ContactForm.jsx';

import info from './Content/info.json';

const App = () => {
  const [language, setLanguage] = useState(() => sessionStorage.getItem('language') || 'es');

  useEffect(() => {
    if (!sessionStorage.getItem('language')) {
      sessionStorage.setItem('language', 'es');
    }
  }, []);

  const BaseURL = `/${language}`;

  return (
    <div className="App">
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <Navbar language={language} setLanguage={setLanguage} info={info[language]} />
        <Routes>
          <Route path="/" element={<Navigate to={`/${language}/`} replace />} />
          <Route path={`${BaseURL}/`} element={<Home language={language} info={info[language]} />} />
          <Route path={`${BaseURL}/sobre_mi`} element={<SobreMi info={info[language]} />} />
          <Route path={`${BaseURL}/resume`} element={<Resume info={info[language]} />} />
          <Route path={`${BaseURL}/portafolio`} element={<Portafolio info={info[language]} />} />
          <Route path={`${BaseURL}/contacto`} element={<ContactForm />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer info={info[language]} />
      </Router>
    </div>
  );
};

export default App;
