import React from 'react';
import '../Home.css';

const PuedesContarConmigo = ({ info }) => {

    const services = info.services;
    
    return (
        <section className="puedes-contar-conmigo">
            <h2>{info.titulo}</h2>
            <h3 className="my-4">{info.descripcion}</h3>

            <div className="container-ayuda">
                <div className="row justify-content-evenly">
                {
                    services.map((inf, index) => (
                        <div key={index} className={`col-12 col-md-5 card-ayuda d-flex me-4 mb-4 ${index % 2 === 0 ? 'icon-right' : 'icon-left'}`}>
                            <div className="card-ayuda__texto">
                                <h3>{inf.titulo}</h3>
                                <p>{inf.descripcion}</p>
                                <span className="price">{inf.subdescripcion}</span>
                            </div>
                            <div className="card-ayuda__icon">
                                <img className="svg-icon" src={inf.icono} alt="" />
                            </div>
                        </div>
                    ))
                }
        
                </div>
            </div>
        </section>
    );
};

export default PuedesContarConmigo;
