import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
        <h1>Lo lamento... Aquí no hay nada</h1>
        <Link className="btn btn-success" to="/">Volver</Link>
    </div>
  )
}

export default NotFound;