import React from "react";
import EnConstruccion from "../../Components/EnConstruccion";

const Portafolio = ({ info }) => {
  return (
    <div className="portafolio-container">
      <EnConstruccion info={info} />
    </div>
  );
};

export default Portafolio;
