import React, { useEffect, useState } from 'react';
import PdfViewer from '../../Components/PdfViewer';

import './Resume.css';

const Resume = ({ info }) => {
    const [selectedCV, setSelectedCV] = useState("");
    const [language, setLanguage] = useState("esp");
    const [pdfKey, setPdfKey] = useState(0);

    const handlePositionSelection = (event) => {
        setSelectedCV(event.target.value);
    };

    const handleLanguageSelection = (event) => {
        setLanguage(event.target.value);
    };

    const filePath = `/static/Documents/CVs/CV ${selectedCV} Jero (${language}).pdf`;

    useEffect(() => {
        setPdfKey(prevKey => prevKey + 1);
    }, [selectedCV, language])

    return (
        <div className="resume-container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h1>{ info.resume.title }</h1>
                    <p>{ info.resume.content[0] }</p>
                    <p>{ info.resume.content[1] }</p>
                    <div className="d-flex flex-column">
                        <select className="form-select my-3" name="language" id="language" onChange={handleLanguageSelection}>
                            <option value="" defaultChecked>{info.resume.chooseLanguage}</option>
                            <option value="esp" >Español</option>
                            <option value="eng">English</option>
                        </select>
                        <select className="form-select my-3" name="position" id="position" onChange={handlePositionSelection} disabled={!language}>
                            <option value="" defaultChecked>{ info.resume.chooseRole }</option>
                            <option value="Dev2">Programador / Developer</option>
                            <option value="Data2">Data Science / Data Analytics / Data Engineering / Big Data / Predictive Analytics</option>
                            {/* <option value="TI">Automatización y Digitalización Empresarial</option> */}
                        </select>
                    </div>
                    <div>
                        <a
                            href={filePath}  // Ruta al archivo PDF
                            download={`CV_${selectedCV}_Jero_${language}.pdf`}  // Nombre del archivo para la descarga
                            className="btn new-btn-secondary my-3"
                        >
                            { info.resume.buttonInfo }
                        </a>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    {selectedCV && language ? (
                        <PdfViewer
                            filePath={filePath}
                            key={pdfKey}
                        />

                    ) : (
                        <p>{ info.resume.waitingMsg}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Resume;
