import React, { useState } from 'react';

const ContactForm = ({ text }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { fullName, email, phone, message } = formData;

    if (!fullName || !email || !phone || !message) {
      setError(text.messages.error);
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(text.messages.invalidEmail);
      return false;
    }

    const phoneRegex = /^[0-9]{9,15}$/;
    if (!phoneRegex.test(phone)) {
      setError(text.messages.invalidPhone);
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await fetch('https://www.santamariai.cl/services/smi_formMailing/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(text.messages.success);
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          message: '',
        });
      } else {
        setError(text.messages.serverError);
      }
    } catch (err) {
      setError(text.messages.serverError);
    }
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form-overlay">
        <h2 className="formHeader">{text.header}</h2>
        <form onSubmit={handleSubmit} className="contact-form">
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}

          <input
            type="text"
            name="fullName"
            placeholder={text.placeholders.fullName}
            value={formData.fullName}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder={text.placeholders.email}
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder={text.placeholders.phone}
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="message"
            placeholder={text.placeholders.message}
            value={formData.message}
            onChange={handleInputChange}
            rows="6"
            required
          />
          <button type="submit">{text.buttons.submit}</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
