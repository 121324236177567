import React from 'react';
import './LogoIcon.css';

const LogoIcon = () => {
    return (
        <div className="icon-container">
            <div className="circle circle1"></div>
            <div className="circle circle2"></div>
        </div>
    );
};

export default LogoIcon;
