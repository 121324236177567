import React from 'react';
import WhatappLogo from '../Static/Icons/Social/whatsapp_logo.webp';
import LinkedinLogo from '../Static/Icons/Social/linkedin_logo.webp';
import GithubLogo from '../Static/Icons/Social/github_logo.webp';
import MailLogo from '../Static/Icons/Social/mail_logo.webp';
import ContactForm from './ContactForm';

const Footer = ({ info }) => {
  return (
    <div className="footer-container bg-primary1">
      <ContactForm text={info.footer.contactForm} />
      <div className="social d-flex justify-content-center">
        <a href="https://wa.me/56994341489" target="_blank" rel="noreferrer"><img className="whatsapp" src={WhatappLogo} alt="WhatsApp" /></a>
        <a href="https://www.linkedin.com/in/jerosantamariai" target="_blank" rel="noreferrer"><img className="linkedin" src={LinkedinLogo} alt="Linkedin" /></a>
        <a href="https://github.com/jerosantamariai" target="_blank" rel="noreferrer"><img className="github" src={GithubLogo} alt="Github" /></a>
        <a href="mailto:jeronimo@santamariai.cl" target="_blank" rel="noreferrer"><img className="mail" src={MailLogo} alt="Mail" /></a>
      </div>
      <div className="signature text-center mt-3">
        <p>{info.footer.signature}</p>
      </div>
    </div>
  );
};

export default Footer;