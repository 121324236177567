import React from 'react'
import { Link } from 'react-router-dom';
import './SobreMi.css';

const SobreMi = ({ info }) => {
  return (
    <div className="container my-5">
      <div className="row">
        <h1 className='mb-4'>{info.aboutMe.header}</h1>
        <h2>{info.aboutMe.name}</h2>
        <p>{info.aboutMe.intro}</p>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col-12 col-md-6">
          <h2>{info.aboutMe.professionalPath.title}</h2>
          <p>{info.aboutMe.professionalPath.content[0]}</p>
          <p>{info.aboutMe.professionalPath.content[1]}</p>
          <p>{info.aboutMe.professionalPath.content[2]}</p>
        </div>
        <div className="col-12 col-md-6">
          <img className='sobreMi-img' src={info.aboutMe.images[0]} alt="" />
        </div>
      </div>

      <div className="row mt-4 mb-5">
        <div className="col-12 col-md-6">
          <img src={info.aboutMe.images[1]} alt="" />
        </div>
        <div className="col-12 col-md-6">
          <h2>{info.aboutMe.projects.title}</h2>
          <p>{info.aboutMe.projects.content}</p>
          <p>{info.aboutMe.projects.cta1} <Link to="/portafolio"><strong>{info.aboutMe.projects.cta2}</strong></Link></p>
        </div>
      </div>

      <div className="row mt-4 mb-5">
        <div className="col-12 col-md-6">
          <h2>{info.aboutMe.values.title}</h2>
          <p>{info.aboutMe.values.content}</p>
        </div>
        <div className="col-12 col-md-6">
          <img src={info.aboutMe.images[2]} alt="" />
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12 col-md-6">
          <img src={info.aboutMe.images[3]} alt="" />
        </div>
        <div className="col-12 col-md-6">
          <h2>{info.aboutMe.skills.title}</h2>
          <ul>
            {
              info.aboutMe.skills.list.map((lis, index) => {
                return (
                  <li key={index}>{lis}</li>
                )
              })
            }
          </ul>
        </div>
      </div>

    </div>
  )
}

export default SobreMi;