import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LogoPrincipal from '../Static/Logos/LogoPrincipal/LogoPrincipal';

import SpainFlag from '../Static/Icons/Global/spain.png';
import EnglandFlag from '../Static/Icons/Global/england.png';

import content from '../Content/info.json';

const Navbar = ({ language, setLanguage, info }) => {
    const navigate = useNavigate(); // Hook para redirigir

    const handleLanguageChange = (lang) => {
        sessionStorage.setItem('language', lang);
        setLanguage(lang);

        // Redirigir a la nueva ruta basada en el idioma
        const newPath = window.location.pathname.replace(`/${language}`, `/${lang}`);
        navigate(newPath === `/${lang}` ? `/${lang}/` : newPath);
    };

    const texts = content[language].navbar;

    return (
        <nav className="navbar navbar-expand-lg bg-primary1 pe-4">
            <Link className="ms-3" to="/">
                <LogoPrincipal info={info} />
            </Link>
            <div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon c-light-emphasis d-flex justify-content-end"></span>
                </button>
            </div>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to={`/${language}/`}>{texts.home}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/${language}/sobre_mi`}>{texts.about}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/${language}/portafolio`}>{texts.portfolio}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/${language}/resume`}>{texts.resume}</Link>
                    </li>
                </ul>
                <div className="ms-3 d-flex">
                    <img className={`flag ${language === 'en' ? 'grayscale' : ''}`} src={SpainFlag} alt="Español" onClick={() => handleLanguageChange('es')} />
                    <img className={`flag ${language === 'es' ? 'grayscale' : ''}`} src={EnglandFlag} alt="English" onClick={() => handleLanguageChange('en')} />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
