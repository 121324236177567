import React from 'react';
import { Link } from 'react-router-dom';

import Jero2 from '../../../Static/Img/Others/jero2.webp';

const QuienSoy = ({ language, info }) => {
    return (
        <div className="container">
            <div className="row py-3 my-3">
                <div className="col-12 col-md-4 d-flex justify-content-center">
                    <div className="prev1"><img className="img-quien-soy" src={Jero2} alt="Jerónimo" /></div>
                </div>
                <div className="col-12 col-md-8">
                    <h1 className='tt-u'>{info.titulo}</h1>
                    <hr className="bg-dark" />
                    <h4 className="my-2"><strong>{info.subtitulo}</strong></h4>
                    <p className="my-2">{info.contenido.parrafos[0]}</p>
                    <p className="my-2">{info.contenido.parrafos[1]}</p>
                    <p className="mt-2 mb-5">{info.contenido.parrafos[2]}</p>
                    <Link className="btn new-btn-secondary mt-6 mb-2" to={`/${language}/sobre_mi`}><strong>{info.boton}</strong></Link>
                </div>
            </div>
        </div>
    );
};

export default QuienSoy;
