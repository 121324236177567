import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs';

const PdfViewer = ({ filePath, key }) => {
    return (
        <PDFViewer
            key={key}
            hideNavbar={true}
            document={{ url: filePath }}
        />
    );
};

export default PdfViewer;