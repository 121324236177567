import React from 'react';
import Carousel from './Content/Carousel';
import QuienSoy from './Content/QuienSoy';
import DirectResume from '../../Components/DirectResume';
import PuedesContarConmigo from './Content/PuedesContarConmigo';

import './Home.css';

const Home = ({ language, info }) => {
  return (
    <div>
        <Carousel />
        <QuienSoy language={language} info={info.home.quienSoy} />
        <DirectResume language={language} info={info.home.resumeShortcut} />
        <PuedesContarConmigo info={info.home.puedesContarConmigo} />
    </div>
  );
}

export default Home;
