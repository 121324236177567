// DirectResume.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const DirectResume = ({ language, info }) => {
    return (
        <div className="bg-primary1">
            <p className="EspecialResume text-center py-5">
                {info.texto}
                <Link to={`/${language}/resume`}>{info.linkTexto}</Link>
            </p>
        </div>
    );
};

export default DirectResume;
